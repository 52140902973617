import React, { useEffect, useRef, useState } from "react";

const Toc = (props) => {
    const tocContainerRef = useRef();
    const [activeH2Title, setActiveH2Title] = useState(props.h2Tags[0]?.title);
    const [isTocExpanded, setIsTocExpanded] = useState(false);

    useEffect(() => {
        const callback = (headings) => {
            const heading = headings[0];
            const title = heading && heading.target.textContent;
            const activeH2Tag = props.h2Tags.find(tag => tag.title === title);
            if (activeH2Tag) {
                props.h2Tags.forEach(tag => {
                    if (tag.title === title) {
                        tag.class = 'active';
                    } else {
                        tag.class = '';
                    }
                })
                if (title && activeH2Tag) {
                    setActiveH2Title(title);
                }
            } else {
                props.h2Tags[0].class = "active";
            }
        }

        const observer = new IntersectionObserver(callback, {
            rootMargin: "10px 0px -85% 0px"
        });

        const headingElements = Array.from(document.querySelectorAll("h2")).filter(heading => {
            return heading.id !== "";
        });
        headingElements.forEach((element) => observer.observe(element));

        return () => {
            observer.disconnect();
        }
    }, [props.h2Tags])

    const toggleToc = () => {
        if (isTocExpanded) {
            // close it
            tocContainerRef.current.className = tocContainerRef.current.className.replace(/ expanded/g, '');
        } else {
            // expand it
            tocContainerRef.current.className = tocContainerRef.current.className + ' expanded';
        }
        setIsTocExpanded(!isTocExpanded);
    }

    return <div className="ez-toc-palcon-wrapper" id="ez-toc-palcon-wrapper">
        <div id="ez-toc-container" ref={tocContainerRef} className="toc">
            <div className="ez-toc-title-container">
                <p className="ez-toc-title">What’s Ahead:</p>
            </div>
            <div className="ez-toc-active-title-container toc-dropdown" onClick={() => {toggleToc();}}>
                <p className="ez-toc-active-title">{activeH2Title}</p>
                <div className="icon" aria-hidden="true"></div>
            </div>
            <nav className="mCustomScrollbar _mCS_1">
                <ul className="ez-toc-list">
                    {
                        props.h2Tags && props.h2Tags.map((tag, i) => <li key={`toc-list-${i}`} className={`toc-item text-base ${tag.class}`} onClick={() => {
                            toggleToc();
                            document.querySelector("#h2-"+i).scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                            });
                        }}>{tag.title}</li>)
                    }
                </ul>
            </nav>
        </div>
    </div>
}

export default Toc;