import React from 'react'
import Layout from '../components/layout'
import {Basic, Facebook, Twitter} from '../components/SEO'
import {metadata} from '../../config'
import loadable from '@loadable/component'
import {
  adDisplayDelay,
  delayMultiplicationFactor,
  formatDate,
  generateArticleSchema,
  generateBreadcrumbSchema,
  generateFaqSchema,
  getCookie,
  googleScriptLoadingDelay,
  isBrowser,
  setCookie,
  setStreamData
} from '../utils/articleUtil'
import {orderBy as _orderBy} from 'lodash'
import {isMobile} from 'react-device-detect'
import Helmet from 'react-helmet'
import queryString from 'query-string'
import {URL} from '../constants/urls'
import consts from '../constants/consts'
import Navigate from '../components/navigate'
import Faq from '../components/faq'

import '../css/components/_article.scss'
import CategoryTitleBordered from '../components/helpers/categoryTitleBordered'
import ReactHtmlParser, {convertNodeToElement} from 'react-html-parser'
import ArticlePopularPosts from '../components/popularPosts/articlePopular'
import {GatsbyImage} from 'gatsby-plugin-image'
import {
  AffiliateDisclosure,
  ArticlesHorizontalLayout,
  ComparisonWidget,
  Highlights,
  track
} from '@cg-squad/ui-components'
import Disclaimer from '../components/disclaimer/disclaimer'
import Toc from "../components/toc"
import mixpanel from '../constants/mixpanel'
import {articlePageAds} from '../utils/adUnits'
import {addTimeout} from '../utils/timeManager'

const AuthorDetails = loadable(() => import('./authorDetails'))
const WhatNext = loadable(() => import('../components/whatNext'))
const FbComments = loadable(() => import('../components/fbComments'))
const Share = loadable(() => import('../components/share/sharing'))

class Article extends React.Component {

  constructor (props) {
    super(props)
    this.data = props.pageContext.article
    this.comparisonPlaceHolderExist = props.pageContext.article.content.includes('comparison-widget-placeholder')
    this.comparisonWidgetAdded = false

    this.state = {
      timeouts: []
    }

    if (isBrowser()) {
      this.checkRedirection()
    }

    this.url = `${metadata.url}/${props.pageContext.url}`
    this.categories = _orderBy(this.data.category, ['position'], ['asc'])
    this.subCategory = this.categories.find(item => item.treeParent !== null)
    setStreamData({
      subCategory: this.subCategory.title,
      category: this.subCategory.treeParent.title,
      article: this.data.heading,
      author: this.data.author.name
    })
    if (isBrowser()) {
      window.streamData = {
        subCategory: this.subCategory.title,
        category: this.subCategory.treeParent.title,
        article: this.data.heading,
        author: this.data.author.name
      }
    }
    this.longAdCount = 0
    this.lastIndexOfPTag = 0
    this.lastIndexOfH2Tag = 0;

    this.getRecommendedPosts()
    this.state = {
      isSubscribeOpen: false,
      activeH2Title: null,
      isTocExpanded: false,
      popularPosts: this.recommendedPosts
    }
    this.h2Tags = [];

    this.articleSchema = generateArticleSchema(this.data, this.subCategory, this.url, this.props.pageContext.wordCount)
    this.faqSchema = generateFaqSchema(this.data)
    this.breadcrumbSchema = generateBreadcrumbSchema(this.data, this.subCategory)

    this.tocContainerRef = React.createRef();
    this.hrefLangData = []
    if (this.data.coUkArticle) {
      this.hrefLangData.push(this.generateHrefLangData(this.data.coUkArticle, 'GB'))
    }
    if (this.data.dotComArticle) {
      this.hrefLangData.push(this.generateHrefLangData(this.data.dotComArticle, 'US'))
    }

    /*this.hrefLangData.push({
      lang: `en-${process.env.GATSBY_DOMAIN === '.com' ? 'US' : 'GB'}`,
      href: this.url
    })*/
  }

  generateHrefLangData (article, countryCode) {
    const categories = _orderBy(article.category, ['position'], ['asc'])
    article.subCategory = categories.find(item => item.treeParent !== null)
    const url = `${article.subCategory.treeParent ? article.subCategory.treeParent.slug + '/' : ''}${article.subCategory.slug}/${article.slug}`
    return {
      lang: `en-${countryCode}`,
      href: `${consts.COUNTRY_WISE_WEBSITE[countryCode].host}/${url}`
    }
  }

  async checkRedirection () {
    const query = queryString.parse(window.location.search)
    const countryFromCookie = query && (query.countryCookie === undefined || query.countryCookie === 'true')
    const countryCode = countryFromCookie && getCookie('country')
    if (countryCode) {
      return this.redirect(countryCode)
    } else {
      fetch(`${URL.SERVER}/geo`)
        .then(x => x.json())
        .then(response => {
          // Get location, threat data and more
          countryFromCookie && setCookie('country', response.countryCode)
          return this.redirect(response.countryCode)
        })
        .catch(error => {
          console.error('Unexpected error', error)
        })
    }
  }

  redirect (countryCode) {
    console.log('country code from cookie', countryCode)
    const redirectTo = consts.COUNTRY_WISE_WEBSITE[countryCode]

    if (redirectTo) {
      const mappedArticle = this.data[redirectTo.mappedDomainField]
      if (!mappedArticle) {
        return
      }
      const categories = _orderBy(mappedArticle.category, ['position'], ['asc'])
      mappedArticle.subCategory = categories.find(item => item.treeParent !== null)
      const url = `${mappedArticle.subCategory.treeParent ? mappedArticle.subCategory.treeParent.slug + '/' : ''}${mappedArticle.subCategory.slug}/${mappedArticle.slug}`
      window.location = `${redirectTo.host}/${url}`
    }
  }

  onSubscriptionPopupStateChange = (state) => {
    this.setState({isSubscribeOpen: state})
  }

  getRecommendedPosts () {
    //this.getRecommendedArticleFromServer();
    this.recommendedPosts = []

    const slugs = []
    let posts = []
    const relatedArticlesObject = this.props.pageContext.relatedArticles
    posts = posts
      .concat(relatedArticlesObject.ai || [])
      .concat(relatedArticlesObject.tags10 || [])
      .concat(relatedArticlesObject.tags9 || [])
      .concat(relatedArticlesObject.tags8 || [])
      .concat(relatedArticlesObject.tags7 || [])
      .concat(relatedArticlesObject.tags6 || [])
      .concat(relatedArticlesObject.tags5 || [])
      .concat(relatedArticlesObject.tags4 || [])
      .concat(relatedArticlesObject.tags3 || [])
      .concat(relatedArticlesObject.tags2 || [])
      .concat(relatedArticlesObject.tags1 || [])
      .concat(relatedArticlesObject.sameCategory || [])
      .concat(relatedArticlesObject.random || [])

    for (let i = 0; i < posts.length; i++) {
      const post = posts[i]
      if (!slugs.includes(post.slug)) {
        this.recommendedPosts.push(post)
        slugs.push(post.slug)
      }
    }
    this.popularPosts = [...this.recommendedPosts]
  }

  /*getRecommendedArticleFromServer () {
    if (!isBrowser()) {
      return
    }
    const category = this.subCategory.treeParent.slug
    const subCategory = this.subCategory.slug
    const _ga = getCookie('_ga')
    if (!_ga || _ga === '') {
      return
    }
    fetch(
      `${URL.SERVER}/articles/recommended?category=${category}&subCategory=${subCategory}&domain=healthtimes.co.uk&article=/${category}/${subCategory}/${this.data.slug}&_ga=${_ga}`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(resp => resp.json())
      .then(res => {
        let articles = (res || []).map(item => {
          return {
            author: {
              name: item.authorData?.name || item.author,
              slug: item.authorData?.url?.split('/')
                .pop()
            },
            categorySlug: item.category,
            subCategorySlug: item.sub_category,
            heading: item.page_title?.split('|')[0].trim(),
            cover: {
              url: `${item.image ? `${item.image.replace('?auto=format', '')}?auto=compress,format&crop=focalpoint&fit=crop&w=300&h=300` : null}`,
              noModify: true
            },
            completePath: item.page_path,
            slug: item.page_path.split('/')
              .pop()
          }
        })
        articles = _uniqBy(articles.concat(this.state.popularPosts), 'slug')
        this.setState({
          popularPosts: articles
        })
      })
  }*/

  getContent () {
    let content = this.data.content.replace(/(?:\r\n|\r|\n)/g, '')
    content = content.replace(/ aria-level="1"/g, '')
    content = content.replace(/ style="font-weight: 400;"/g, '')
    content = content.replace(/ padding-left:40px/g, '')
    content = content.replace(/src="https:\/\/www.youtube.com\/embed/g, 'data-src="https://www.youtube.com/embed')
    return content
  }

  getComparisonWidget = () => {
    this.comparisonWidgetAdded = true
    return <ComparisonWidget title={this.data.comparisonTable.title}
                             summary={this.data.comparisonTable.summary}

                             belowSection={this.data.comparisonTable.belowSection}
                             data={JSON.parse(this.data.comparisonTable.comparisonData)}/>
  }

  transform = (node, index) => {
    // TODO fix this domnesting issue div within p tag
    /*if (node.type === 'tag' && node.name === 'img') {
      return <div style={{textAlign: "center"}}>
        {convertNodeToElement(node, index, null)}
      </div>;
    }*/
    if (node.type === 'tag' && node.name === 'table') {
      node.attribs.style = 'margin-left: auto; margin-right: auto;' + (node.attribs.style ? node.attribs.style : '')
      return convertNodeToElement(node, index, null)
    }

    // Comparison widget
    if (!this.comparisonWidgetAdded && this.data.comparisonTable && this.data.comparisonTable.comparisonData) {
      if (this.comparisonPlaceHolderExist) {
        if (node.type === 'tag' && node.name === 'div' && node.attribs.class === 'comparison-widget-placeholder') {
          return this.getComparisonWidget()
        }
      } else {
        if (node.type === 'tag' && node.name === 'h2') {
          return <div>
            {this.getComparisonWidget()}
            {convertNodeToElement(node, index, null)}
          </div>
        }
      }
    }

    if (node.type === 'tag' && node.name === 'h2') {
      const title = node.children[0].data || node.children[0].children[0].data;
      ++this.lastIndexOfH2Tag;
      this.h2Tags.push({title, class: this.lastIndexOfH2Tag === 1 ? 'active' : ''});
      node.attribs.id = `h2-${this.lastIndexOfH2Tag - 1}`;
      convertNodeToElement(node, index, null)
  }

    if (node.type === 'tag' && node.name === 'p' && (!node.parent || node.parent.name === 'div') && !(node.attribs.class || '').includes('affiliate-link')) {
      ++this.lastIndexOfPTag
      let customElements = []
      if (this.lastIndexOfPTag === 2) {
        customElements.push(<div className="ad-container">
          <div className="advertisement-text">Advertisement</div>
          <div id="ht_article_incontent1"/>
        </div>)
      }
      if (this.lastIndexOfPTag === 7) {
        customElements.push(<div className="ad-container">
          <div className="advertisement-text">Advertisement</div>
          <div id="ht_article_incontent2"/>
        </div>)
      }
      if (this.lastIndexOfPTag === 12) {
        customElements.push(<div className="ad-container">
          <div className="advertisement-text">Advertisement</div>
          <div id="ht_article_incontent3"/>
        </div>)
      }
      if (this.lastIndexOfPTag >= 17 && this.lastIndexOfPTag % 5 === 2 && this.longAdCount < 4) {
        ++this.longAdCount
        customElements.push(<div className="ad-container">
          <div className="advertisement-text">Advertisement</div>
          <div id={`ht_article_incontent-0${this.longAdCount}`} className="long-ad-container"/>
        </div>)
      }
      if (isMobile && this.lastIndexOfPTag === 5) {
        customElements.push(
          <WhatNext recommendedPosts={this.state.popularPosts.splice(0, isMobile ? 2 : 4)}/>
        )
      }

      if (customElements.length) {
        return <div>
          {convertNodeToElement(node, index, null)}
          {customElements.map(element => element)}
        </div>
      }
    }
  }

  getUrlForFacebook () {
    if (isBrowser()) {
      return `${window.location.origin}/${this.props.pageContext.url}`
    } else {
      return this.url
    }
  }

  componentDidMount () {
    addTimeout(() => {
      const articleDom = document.getElementById('article-content')
      const children = articleDom.children
      const secondLastElement = children[children.length - 2]
      if (secondLastElement?.tagName === 'DIV' && secondLastElement?.className === 'ad-container') {
        if (this.longAdCount > 0) {
          this.longAdCount--
        }
        //secondLastElement.remove();
        secondLastElement.style.display = 'none'
      }
      articlePageAds([
          {key: 'category', value: this.subCategory.treeParent.title},
          {key: 'subcategory', value: this.subCategory.slug},
          {key: 'slug', value: this.data.slug}
        ],
        this.longAdCount)
    }, adDisplayDelay() * delayMultiplicationFactor(isMobile))

    const youtubeVideoDisplay = () => {
      const vidDefer = document.getElementsByTagName('iframe')
      for (let i = 0; i < vidDefer.length; i++) {
        if (vidDefer[i].getAttribute('data-src')) {
          vidDefer[i].setAttribute('src', vidDefer[i].getAttribute('data-src'))
        }
      }
    }

    if (googleScriptLoadingDelay() === consts.SCRIPT_LOADING_TIME_WAIT) {
      window.onload = () => {
        addTimeout(() => {
          youtubeVideoDisplay()
        }, 2000 * delayMultiplicationFactor(isMobile))
      }
    } else {
      addTimeout(() => {
        youtubeVideoDisplay()
      }, 2000 * delayMultiplicationFactor(isMobile))
    }

    track(mixpanel.MIXPANEL_PAGE_VIEW.ARTICLE)

    if (isBrowser()) {
      window.addEventListener('scroll', this.handleScroll.bind(this), {passive: true})
    }
     /*if (this.h2Tags && this.h2Tags.length) {
            this.setState({
                h2Tags: this.h2Tags,
                activeH2Title: this.h2Tags[0].title
            });
        }*/

        if (this.state.h2Tags?.length !== this.h2Tags.length) {
          this.comparisonWidgetAdded = false;
          this.setState({
              h2Tags: this.h2Tags
          })
      }

    //this.state.timeouts.push();

    /*addTimeout(() => {
      this.addOutbrainScript()
    }, 4200 * delayMultiplicationFactor(isMobile))*/
  }

  handleScroll () {
    // TODO need to optimize
    const h = document.documentElement,
      b = document.body,
      st = 'scrollTop',
      sh = 'scrollHeight'
    const percent = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100
    if (percent >= 25 && !this.tracked25Scroll) {
      this.tracked25Scroll = true
      track(mixpanel.MIXPANEL_SCROLL.ARTICLE, {scrollValue: 25})
    }
    if (percent >= 50 && !this.tracked50Scroll) {
      this.tracked50Scroll = true
      track(mixpanel.MIXPANEL_SCROLL.ARTICLE, {scrollValue: 50})
    }
    if (percent >= 75 && !this.tracked75Scroll) {
      this.tracked75Scroll = true
      track(mixpanel.MIXPANEL_SCROLL.ARTICLE, {scrollValue: 75})
    }
    if (percent >= 98 && !this.tracked100Scroll) {
      this.tracked100Scroll = true
      track(mixpanel.MIXPANEL_SCROLL.ARTICLE, {scrollValue: 100})
    }
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  /*addOutbrainScript() {
    const headElement = document.getElementsByTagName("head")[0];
    if (document.querySelector('script[src*="widgets.outbrain.com"]')) {
      window.OBR.extern.reloadWidget();
      return;
    }
    const outbrainScript = document.createElement("script");
    outbrainScript.src = 'https://widgets.outbrain.com/outbrain.js';
    outbrainScript.type = "text/javascript";
    outbrainScript.async = true;
    headElement.appendChild(outbrainScript);
  }*/

  render () {
    this.lastIndexOfPTag = 0
    this.lastIndexOfH2Tag = 0;
    this.longAdCount = 0;
    this.h2Tags = [];
    return <Layout preloadImage={this.data.cover.gatsbyImageData}
                   isSubscribeOpen={this.state.isSubscribeOpen}
                   onSubscriptionPopupStateChange={this.onSubscriptionPopupStateChange}
                   relativePath={this.props?.pageContext?.url}
                   pageType="article"
                   articleSlug={this.data.slug}>
      <Helmet>
        <link rel="canonical" href={this.url}/>
        <meta name="keywords" content={this.data.tags}/>
        <script type="application/ld+json">{JSON.stringify(this.articleSchema)}</script>
        {this.faqSchema &&
          <script type="application/ld+json">{JSON.stringify(this.faqSchema)}</script>}
        <script type="application/ld+json">{JSON.stringify(this.breadcrumbSchema)}</script>
        {this.props.pageContext.noindex && <meta name="robots" content="noindex"/>}
        {this.hrefLangData && this.hrefLangData.length > 0 && this.hrefLangData.map(hrefData => {
          return <link rel="alternate" href={hrefData.href} hrefLang={hrefData.lang}/>
        })}
        {this.hrefLangData && this.hrefLangData.length > 0 &&
          <html lang={process.env.GATSBY_DOMAIN === '.com' ? 'en-US' : 'en-GB'}/>}
        {/*<link rel="prefetch" href="https://wct-2.com/wct.js" as="script"/>*/}
      </Helmet>
      <Basic seo={this.data.seo}/>
      <Twitter seo={this.data.seo} url={this.url}/>
      <Facebook seo={this.data.seo} url={this.url}/>
      <main className="wrapper main-data-container article-page pt-5">
        <div className="bg-gray-220 grid lg:grid-cols-2 items-center mb-10">
          <div className="px-8 lg:pl-12 py-10 lg:py-4 lg:col-span-1">
            <div
              className="font-news italic font-semibold text-teal-600 text-2xl">{this.subCategory.title}</div>
            <h1 className="font-normal leading-none text-3xl mb-2">{this.data.heading}</h1>
            {this.data.excerptHtml
              ? <div className="font-sans text-base lg:text-lg leading-snug -mb-4 article-excerpt"
                     dangerouslySetInnerHTML={{__html: this.data.excerptHtml}}/>
              : <p
                className="font-sans text-base lg:text-lg leading-snug mb-0">{this.data.excerpt}</p>}
            <div className="flex items-center text-15 mt-6" data-datocms-noindex>
              <Navigate
                href={`/authors/${this.data.author.slug}`}
                className={'text-denim article-author-name'}>{this.data.author.name}
              </Navigate>
              <span>&nbsp;-&nbsp;{this.props.pageContext.readingTime} Min Read</span>
              {this.data.isSponsored && <span>&nbsp;·&nbsp;sponsored</span>}
            </div>
            <div className="text-15">Last updated and fact checked:
              <time
                className={'ml-1'}>{formatDate(this.data.meta.publishedAt, 'mmmm dd, yyyy')}</time>
            </div>
          </div>
          <div className="lg:col-span-1">
            <GatsbyImage loading="eager" className="max-h-[290px] lg:max-h-[320px]"
                         alt={this.data.heading}
                         image={this.data.cover.gatsbyImageData}/>
          </div>
        </div>
        <div className={'w-full lg:flex'}>
          <article className="lg:w-[calc(100%-317px)]">
            <section className="font-arial">
              {this.data.highlights &&
                <Highlights className={'mb-4 lg:mb-6'} data={this.data.highlights}/>}
              <div className="lg:hidden"><Share/></div>
              {this.data.json && this.data.json.faq &&
                <Faq data={this.data.json.faq}
                     title={this.data.json.faqTitle ? this.data.json.faqTitle : `${this.data.heading}: FAQs`}/>}
              <AffiliateDisclosure title="Health Times"/>

            </section>
            <main doc-container="main" className={'relative'}>

              {this.state.h2Tags && this.state.h2Tags.length > 0 && <Toc h2Tags={this.state.h2Tags}/>}
              <div id="article-content">
                {ReactHtmlParser(this.getContent(), {transform: this.transform})}
                <div className="ad-container">
                  <div className="advertisement-text">Advertisement</div>
                  <div id="ht_article_endcontent"/>
                </div>
                {isMobile && <WhatNext
                  recommendedPosts={this.state.popularPosts.splice(0, isMobile ? 2 : 4)}/>}
              </div>
              {!isMobile && <div className={'absolute'} style={{top: 0, left: '-65px'}}>
                <Share/>
              </div>}
            </main>
            <FbComments url={this.getUrlForFacebook()}/>
            {this.subCategory.treeParent && !['videos'].includes(this.subCategory.treeParent.slug) &&
              <Disclaimer/>}
            <div className="OUTBRAIN" data-widget-id="GS_1"></div>
          </article>
          {!isMobile &&
            <ArticlePopularPosts articles={this.state.popularPosts} imageLoading="eager"/>}
        </div>
        <div className="border-t mt-4">
          <div className="flex justify-center items-center my-4 text-3xl font-libre">See More</div>
          <ArticlesHorizontalLayout articles={this.state.popularPosts.splice(3, 4)}/>
        </div>
      </main>
    </Layout>
  }
}

export default Article
